import React from 'react'
import PropTypes from 'prop-types'


class EmailLink extends React.Component{
    render(){
        return(
            <li><a href={`mailto:${this.props.name}@JusticePorkGames.com`} className="icon" title={`${this.props.name}@JusticePorkGames.com`}><strong>{this.props.abbr}</strong></a> </li>
        ) 
    }
}

EmailLink.propTypes = {
    name:PropTypes.string,
    abbr:PropTypes.string
  }

export default EmailLink