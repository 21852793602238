import React from 'react'

class JonNickerson extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://www.JonathanNickerson.com/">Jon Nickerson</a> – Level Designer</h3>
                <p>Memes are life and life is good.</p>
            </div>
        ) 
    }
}

export default JonNickerson