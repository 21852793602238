import React from 'react'

class BrieaDowse extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://brieadowse.artstation.com/">Briea Dowse</a> – Environment</h3>
                <p>Chief of the Briea-people, and creator of slugs.</p>
            </div>
        ) 
    }
}

export default BrieaDowse