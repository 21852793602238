import React from 'react'

class NickMagnus extends React.Component{
    constructor(props){
        super(props);
        this.nickMagnus = this.nickMagnus.bind(this);
    }

    nickMagnus(){
        let beginnings = [
            "B",
            "Bl",
            "Br",
            "Bu",
            "C",
            "Ch",
            "Cl",
            "Cr",
            "Dw",
            "Dr",
            "Fl",
            "Fr",
            "G",
            "Gl",
            "Gr",
            "H",
            "J",
            "K",
            "Kl",
            "Kn",
            "Kr",
            "Kw",
            "L",
            "M",
            "N",
            "P",
            "Pl",
            "Pr",
            "Ph",
            "Qu",
            "R",
            "S",
            "Sc",
            "Sch",
            "Schn",
            "Schw",
            "Sh",
            "Shn",
            "Sl",
            "Sm",
            "Sn",
            "St",
            "Str",
            "Sw",
            "T",
            "Th",
            "Thr",
            "Tr",
            "Tw",
            "V",
            "W",
            "Wh",  
            "Wr",
            "Y",
            "Z"
            ]

        let num = beginnings.length;
        let r1 = Math.floor(Math.random() * num);
        let r2 = Math.floor(Math.random() * num);
        let name = beginnings[r1] + "ick " + beginnings[r2] + "agnus"
        return name;
    }
    
    render(){
        return(
            <div>
                <h3><a href="https://NickMagnusDev.com">Nick Magnus</a> – Lead Designer</h3>
                <p>{this.nickMagnus()} is a technical designer well known for his work as one of the top chefs at JorgerBurger&reg;.</p>
            </div>            
        )
    }
}

export default NickMagnus