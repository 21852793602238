import React from 'react'

class GabeTroyan extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://gabe.ws">Gabe Troyan</a> – Lead Programmer</h3>
                <p>Platyformer™ developer.</p>
            </div>
        ) 
    }
}

export default GabeTroyan