import React from 'react'

class BrennanHowell extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://bdahowell.artstation.com/">Brennan Howell</a> – Environment & VFX</h3>
                <p>Currently addressing the balloon elephant in the room.</p>
            </div>
        ) 
    }
}

export default BrennanHowell