import React from 'react'

class RosserMartinez extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://www.rossermartinez.com/">Rosser Martinez</a> – Gameplay</h3>
                <p>If it’s not inspired by <em>Tekken</em> or <em>MGS2</em>, something’s wrong.</p>
            </div>
        ) 
    }
}

export default RosserMartinez