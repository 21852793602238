import React from 'react'

class JonVogt extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://jonathancvogt.weebly.com/">Jon Vogt</a> – Narrative Designer </h3>
                <p>uwu; where are the Gates?</p>
            </div>
        ) 
    }
}

export default JonVogt