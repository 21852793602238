import React from 'react'

class KellyHillis extends React.Component{
    render(){
        return(
            <div>
                <h3>Kelly Hillis</h3>
                <p>Here to help set our game apart from the visual novels on Itch.io.</p>
            </div>
        ) 
    }
}

export default KellyHillis