/* eslint-disable react/no-unescaped-entities */
import React from 'react'

import trailer from "../../static/videos/trailer.mp4"

const GameDescription = () => (
    <div>
        <p>
            Can you heal the corrupted land, defeat dangerous creatures, and uncover the mystery
            behind your caretaker’s disappearance? Your mechanical arm is the key. Scale perilous
            cliffs, swing through the sky, fight through hordes of corrupted enemies, and uncover
            new power-ups along the way.
        </p>
        <video src={trailer} width="100%" height="auto" loop={true} autoPlay={true} controls="shown" muted={true}></video>
        <p></p>
        <p>
            <em>Stray: The Wayward Path</em> is a third-person action adventure game where you play as Aapo, a
            boy who wields a powerful mechanical arm. Years ago, tainted by your people's misuse of its
            magic, the revered volcano, Chi'Lito, erupted. The eruption blanketed the land in corruption,
            turning its wildlife hostile.
        </p>
        <p>
            In the devastating aftermath, your people scattered and you were abandoned. But the shaman, 
            Kunara, took you in and raised you as her own. Together, you have learned to survive in the 
            war-torn world. However, one day, you awake to find Kunara missing. Why? Follow the tracks 
            left behind by Kunara. Overcome dangerous foes to become more powerful. Discover the history
            of your lost people, and uncover your role in an unfolding prophecy. Can you save your people?
        </p>
    </div>
);

export default GameDescription