import React from 'react'

class JocelynBedell extends React.Component{

    render(){
        return(
            <div>
                <h3><a  href="https://fellen.artstation.com">Jocelyn Bedell</a> – Lead Artist</h3>
                <p>Responsible for the birth of our boy.</p>
            </div>
        ) 
    }   
}



export default JocelynBedell