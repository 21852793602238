import React from 'react'

class LennyGingello extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://LennyGingello.com/">Lenny Gingello</a> – Level Designer</h3>
                <p>“I love kimchi.”</p>
            </div>
        ) 
    }
}

export default LennyGingello