import React from 'react'

class LydiaSelman extends React.Component{
    render(){
        return(
            <div>
                <h3><a href='https://www.linkedin.com/in/lydiaselman/'>Lydia Selman</a> – Composer</h3>
                <p><em>Insert obligatory hedgehog-themed joke here</em></p>
            </div>
        ) 
    }
}

export default LydiaSelman