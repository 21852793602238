import React from 'react'

class SummerSoftleigh extends React.Component{
    render(){
        return(
            <div>
                <h3><a href='https://www.linkedin.com/in/summersoftleigh/'>Summer Softleigh</a> – Gameplay & UI</h3>
                <p>Proficient at cutting rubies on a train.</p>
            </div>
        ) 
    }
}

export default SummerSoftleigh