import React from 'react'

class DustinYost extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://dustinyost.com/">Dustin Yost</a> – Gameplay, AI & Tools </h3>
                <p>Favorite flavor of yogurt is gas.</p>
            </div>
        ) 
    }
}

export default DustinYost