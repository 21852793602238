import React from 'react'

class EduardoFernández extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://eduardoefernandez.wordpress.com/">Eduardo Fernández</a> – Producer</h3>
                <p>Working with necromancers has given him a new perspective on life.</p>
            </div>
        ) 
    }
}

export default EduardoFernández