import React from 'react'

class JasonCotugno extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://jcotugno.artstation.com/">Jason Cotugno</a> – Props & Animations</h3>
                <p>Really likes <em>Kingdom Hearts χ</em>.</p>
            </div>
        ) 
    }
}

export default JasonCotugno