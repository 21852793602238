import React from 'react'

class GavinHieronymus extends React.Component{
    render(){
        return(
            <div>
                <h3><a href="https://www.linkedin.com/in/gavinhieronymus/">Gavin Hieronymus</a> – Chief QA Liaison</h3>
                <p>Mostly here to carry Gabe out of bronze Joust.</p>
            </div>
        ) 
    }
}

export default GavinHieronymus