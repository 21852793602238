import React from 'react'
import Howler from 'react-howler'
import PropTypes from 'prop-types'

import EmailLink from './EmailLink.js'

import NickMagnus from    './personnel/NickMagnus.js'
import BrieaDowse from    './personnel/BrieaDowse.js'
import JocelynBedell from './personnel/JocelynBedell.js'
import GabeTroyan from    './personnel/GabeTroyan.js'
import JonNickerson from  './personnel/JonNickerson.js'
import JonVogt from       './personnel/JonVogt.js'
import RosserMartinez from './personnel/RosserMartinez.js'
import DustinYost from './personnel/DustinYost.js'
import BrennanHowell from './personnel/BrennanHowell.js'
import JasonCotugno from './personnel/JasonCotugno.js'
import LennyGingello from './personnel/LennyGingello.js'
import EduardoFernández from './personnel/EduardoFernández.js'
import GavinHieronymus from './personnel/GavinHieronymus.js'
import SummerSoftleigh from './personnel/SummerSoftleigh.js'
import LydiaSelman from './personnel/LydiaSelman.js'
import KellyHillis from './personnel/KellyHillis.js'
import GameDescription from './GameDescription'

import splash from '../images/stray_splash.png'
import jpg from '../images/JusticePork.png'
import song from '../stray.m4a'
import reel from '../../static/videos/reel.mp4';

import smartquotes from 'smartquotes';

const winDownloadLink = 'https://github.com/JusticePorkGames/Stray-Releases/releases/download/v1.0.6b3/Stray-Windows.zip';
const macDownloadLink = 'https://github.com/JusticePorkGames/Stray-Releases/releases/download/v1.0.6b3/Stray-Mac.tbz2';
const linuxDownloadLink = 'https://github.com/JusticePorkGames/Stray-Releases/releases/download/v1.0.6b3/Stray-Linux.7z';

class Main extends React.Component {
  platform = 'Windows';
  
  constructor(props){
    super(props);
    this.state = {
      playStray: false,
    }
    this.handleSubmit = this.handleSubmit.bind(this);
    this.name = React.createRef();
    this.email = React.createRef();
    this.message = React.createRef();
    this.handleStrayClick = this.handleStrayClick.bind(this);
    this.toggleStrayState = this.toggleStrayState.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);

    // Reference to team reel element
    this.reelComp = React.createRef();
  }

  handleSubmit(event){
    let url = 'https://docs.google.com/forms/d/e/1FAIpQLSfkfC3GIfe3vnmPF2ogR9iTywoZpMdTmkj0QFH2EhoHwC0C9A/viewform?usp=pp_url&entry.42628731=name&entry.1542978052=email&entry.1869170779=inquiry';
    window.open(url.replace("name",this.name.current.value).replace("email",this.email.current.value).replace('inquiry',this.message.current.value));
    
    event.preventDefault();
  }

  componentDidMount(){
    document.addEventListener('keydown', this.handleKeyDown);

    // open page with query string
    const params = new URLSearchParams(window.location.search);
    if(params.has("p")){
      this.props.onOpenArticle(params.get("p"));
    }      

    // Check if we're a Mac for download link
    if(navigator.appVersion.indexOf("Mac") !== -1){
      this.platform = "Mac";
    }else if(navigator.appVersion.indexOf("Linux") !== -1){
      this.platform = "Linux";
    }

    smartquotes();
  }

  handleStrayClick(){
    this.toggleStrayState();
  }

  handleKeyDown(event){
    if(event.key === 'm'){
      if(this.toggleStrayState)
      this.toggleStrayState();
    }
  }

  toggleStrayState(){
    this.props.updateParent(!this.state.playStray);
    this.setState({playStray:!this.state.playStray});    
  }

  render() {    
    
    let close = <div className="close" onClick={() => {this.props.onCloseArticle(); this.realComp.pause()}}></div>
    let frame = <Howler playing={this.state.playStray} src={song} ref={ref => {this.howl = ref}} onPause={()=>this.howl.seek(0)} volume={.35}/>;
    
    const reelComp = <video src={reel} width="100%" height="auto" loop={true} poster={jpg} controls="shown" ref={ref => {this.realComp = ref}}></video>
    const downloadText = <span><span className="icon fa-download"></span>&nbsp;Download&nbsp;</span>;

    const DownloadButton = ({icon, link, platform, ...props}) => {
      return <a title={platform} className= "downloadLink" name={icon} onClick={() => window.location=link} {...props}>{this.platform === platform ? downloadText : <span></span> }<span className={icon}></span></a>;
    }
    
    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>
        {frame}
        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Intro</h2>
          {/* <span className="image main"><img src={jpg} alt="" /></span> */}
          {reelComp}
          <p>Here at Team JPG, we live by one guiding principle: serving cold, hard, dubiously kosher justice.</p>
          {close}
        </article>

        <article id="stray" className={`${this.props.article === 'stray' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 style={{cursor:'pointer'}} className="major" onClick={this.handleStrayClick}>Stray <span style={{fontSize:`${.8}em`} }>The Wayward Path</span></h2>
          <span className="image main"><img src={splash} alt="" /></span>
          <GameDescription/>
          <DownloadButton platform={"Windows"} icon = {"icon fa-windows"} link={winDownloadLink} ></DownloadButton>
          <span>&nbsp;&nbsp;</span>
          <DownloadButton platform={"Mac"} icon = {"icon fa-apple"} link={macDownloadLink} ></DownloadButton>
          <span>&nbsp;&nbsp;</span>
          <DownloadButton platform={"Linux"} icon = {"icon fa-linux"} link={linuxDownloadLink} ></DownloadButton>
          
          {close}
        </article>

        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Team JPG</h2>
          {/*<span className="image main"><img src={pic03} alt="" /></span>*/}
          <h2>Art</h2>
          <JocelynBedell/>
          <br/>
          <BrieaDowse/>
          <br></br>
          <BrennanHowell/>
          <br/>
          <JasonCotugno/>
          <br/>
          <h2>Design</h2>          
          <NickMagnus/>
          <br/>
          <JonNickerson/>
          <br></br>
          <LennyGingello/>
          <br/>
          <h2>Programming</h2>
          <GabeTroyan></GabeTroyan>
          <br/>
          <RosserMartinez/>
          <br></br>
          <DustinYost/>
          <br/>
          <SummerSoftleigh/>
          <br/>
          <h2>Production</h2>
          <EduardoFernández/>
          <br/>
          <GavinHieronymus/>
          <br/>
          <h2>Narrative</h2>
          <JonVogt></JonVogt>
          <br/>
          <h2>Sonic Arts</h2>
          <LydiaSelman/>
          <br/>
          <h2>Marketing</h2>
          <KellyHillis/>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <form onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" ref={this.name} />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" ref={this.email}/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4" ref={this.message}></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form>
          <ul className="icons">
            {/* 
            <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li>
            <li><a href="#" className="icon fa-github"><span className="label">GitHub</span></a></li> */}
            <li><a href="https://www.facebook.com/JusticePorkGames/" className="icon fa-facebook" title="Justice Pork Games"><span className="label">Facebook</span></a></li>
            <li><a href="https://twitter.com/JusticePork" className="icon fa-twitter" title="@JusticePork"><span className="label">Twitter</span></a></li>
            <li><a href="https://www.youtube.com/channel/UCsYBOr83S1YKRCE4VmaFGlQ" className="icon fa-youtube" title="YouTube"><span className="label">YouTube</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  updateParent: PropTypes.func
}

export default Main