import React from 'react'
import PropTypes from 'prop-types'

import jpg from '../images/JusticePork.png'

const buttonStyle = {
    cursor: 'pointer'
}

const Header = (props) => (
    <header id="header" style={props.timeout ? {display: 'none'} : {}}>
        <div className="logo">
            <img style={{maxHeight:110 + "%",maxWidth:110+"%",padding:0+"%",paddingTop:8+"%", paddingRight:10+"%"} } src={jpg}></img>
        </div>
        <div className="content">
            <div className="inner">
                <h1>Justice Pork Games</h1>
                <p>We don’t serve pork here. We serve justice.</p>
            </div>
        </div>
        <nav>
            <ul>                
                <li><a name="javascript:;" style={buttonStyle} onClick={() => {props.onOpenArticle('intro')}}>Intro</a></li>
                <li><a name="javascript:;" style={buttonStyle} onClick={() => {props.onOpenArticle('stray')}}>Stray</a></li>
                <li><a name="javascript:;" style={buttonStyle} onClick={() => {props.onOpenArticle('about')}}>Team JPG</a></li>
                <li><a name="javascript:;" style={buttonStyle} onClick={() => {props.onOpenArticle('contact')}}>Contact</a></li>
            </ul>
        </nav>
    </header>
)

Header.propTypes = {
    onOpenArticle: PropTypes.func,
    timeout: PropTypes.bool
}

export default Header
